import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import dropoffIcon from '../../assets/icons/dropoffs.svg';
import addTransactionIcon from '../../assets/icons/transactions.svg';
import AddButton from '../../components/ui/common/AddButton';
import Breadcrumb from '../../components/ui/common/Breadcrumb';
import FilterMark from '../../components/ui/common/FilterMark';
import RangeDateField from '../../components/ui/common/Inputs/RangeDateField';
import Searchbar from '../../components/ui/common/Searchbar';
import SentryInfiniteScroll from '../../components/ui/common/SentryInfiniteScroll';
import LoaderSmall from '../../components/ui/LoaderSmall';
import DropoffCard from '../../components/ui/vendors/dropoffs/DropoffCard';
import StatCard from '../../components/ui/vendors/StatCard';
import useDebouceTime from '../../hooks/useDebounceTime';
import useDropoffList from '../../hooks/useDropoffList';
import { useModal } from '../../hooks/useModal';
import { dropoffsFiltersState } from '../../recoil/dropoffFilters';
import { dropoffsCleanFiltersState } from '../../recoil/dropoffsCleanFilter';
import { needRefreshState } from '../../recoil/needRefresh';
import {
  GET_DROPOFF_OPTIONS,
  GET_DROPOFF_STATS,
} from '../../services/graphql/vendors/dropoffs/dropoffsQuery';
import type {
  GetDropoffOptionsResponse,
  GetDropoffsStatsResponse,
} from '../../types/dropoffs';
import {
  formatNumber,
  formatNumberMoney,
  formatNumberPercentage,
} from '../../utils/numberFormatter';

const DropoffsView = () => {
  const { t } = useTranslation('common');
  const [filters, setFilters] = useRecoilState(dropoffsFiltersState);
  const { handleOpenDropoffsFilterModal, handleOpenNewDropoffModal } =
    useModal();
  const [needRefresh] = useRecoilState(needRefreshState);

  const { filterValue, handleFilterChangeWithDebounce } = useDebouceTime({
    setFilters,
    filters,
    key: 'vendorNameFilter',
  });

  // === QUERIES ===
  const { data: options } =
    useQuery<GetDropoffOptionsResponse>(GET_DROPOFF_OPTIONS);

  const { data: metrics, refetch: refetchStats } =
    useQuery<GetDropoffsStatsResponse>(GET_DROPOFF_STATS, {
      variables: {
        ...filters,
        totalGallonsMin: filters?.totalGallons
          ? filters?.totalGallons[0]
          : null,
        totalGallonsMax: filters?.totalGallons
          ? filters?.totalGallons[1]
          : null,
        avgMiuMin: filters?.avgMiu ? filters?.avgMiu[0] : null,
        avgMiuMax: filters?.avgMiu ? filters?.avgMiu[1] : null,
        gallonPriceMin: filters?.gallonPrice ? filters?.gallonPrice[0] : null,
        gallonPriceMax: filters?.gallonPrice ? filters?.gallonPrice[1] : null,
        totalPaidMin: filters?.totalPaid ? filters?.totalPaid[0] : null,
        totalPaidMax: filters?.totalPaid ? filters?.totalPaid[1] : null,
        ffaMin: filters?.ffa ? filters?.ffa[0] : null,
        ffaMax: filters?.ffa ? filters?.ffa[1] : null,
        sulfurMin: filters?.sulfur ? filters?.sulfur[0] : null,
        sulfurMax: filters?.sulfur ? filters?.sulfur[1] : null,
      },
    });

  const {
    fetchMoreDropoffs,
    loading,
    dropoffs,
    hasNextPage,
    errorTimeline,
    loadingSingleQuery,
    refetchSingleDropoff,
    refetchDropoffs,
  } = useDropoffList({
    ...filters,
    totalGallonsMin: filters?.totalGallons ? filters?.totalGallons[0] : null,
    totalGallonsMax: filters?.totalGallons ? filters?.totalGallons[1] : null,
    avgMiuMin: filters?.avgMiu ? filters?.avgMiu[0] : null,
    avgMiuMax: filters?.avgMiu ? filters?.avgMiu[1] : null,
    gallonPriceMin: filters?.gallonPrice ? filters?.gallonPrice[0] : null,
    gallonPriceMax: filters?.gallonPrice ? filters?.gallonPrice[1] : null,
    totalPaidMin: filters?.totalPaid ? filters?.totalPaid[0] : null,
    totalPaidMax: filters?.totalPaid ? filters?.totalPaid[1] : null,
    ffaMin: filters?.ffa ? filters?.ffa[0] : null,
    ffaMax: filters?.ffa ? filters?.ffa[1] : null,
    sulfurMin: filters?.sulfur ? filters?.sulfur[0] : null,
    sulfurMax: filters?.sulfur ? filters?.sulfur[1] : null,
  });
  const location = useLocation();
  const cleanFilters = useRecoilValue(dropoffsCleanFiltersState);

  // On before leave
  useEffect(() => {
    // clean filters when leaving component
    setFilters(cleanFilters);
  }, [location.key]);

  useEffect(() => {
    if (needRefresh === 'dropoffs') {
      refetchDropoffs();
      refetchStats();
    } else if (needRefresh === 'dropoffs-stats') {
      refetchStats();
    }
  }, [needRefresh]);

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: fetchMoreDropoffs,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!errorTimeline,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 20px 0px',
  });

  return (
    <>
      <div className="flex w-full flex-col gap-y-2">
        <Breadcrumb title={t('dropoffs.title')} image={dropoffIcon} />
        {/* Filters  */}
        <div className="my-2 flex h-full w-full justify-between gap-4">
          <Searchbar
            filterValue={filterValue}
            onChange={handleFilterChangeWithDebounce}
            placeholder={t('dropoffs.searchbar')}
            handleOpenFilterModal={handleOpenDropoffsFilterModal}
          />
          <div className="relative flex gap-4">
            <AddButton
              icon={addTransactionIcon}
              id="newVendorButton"
              handleAction={handleOpenNewDropoffModal}
            />
            <RangeDateField
              endDate={filters?.endDate!}
              startDate={filters?.startDate!}
              saveAsString
              onSelectEndChange={(val) =>
                setFilters({
                  ...filters,
                  endDate: val as string,
                })
              }
              onSelectStartChange={(val) => {
                setFilters({
                  ...filters,
                  startDate: val as string,
                });
              }}
              updateDates={(start, end) => {
                setFilters({
                  ...filters,
                  startDate: start as string,
                  endDate: end as string,
                });
              }}
              defaultDropdown="last_30"
            />
          </div>
        </div>
        <div className="my-2 flex w-full flex-wrap gap-2">
          {filters &&
            Object.entries(filters).map(
              ([name, value]) =>
                value !== null &&
                value !== undefined &&
                name !== 'vendorNameFilter' &&
                name !== 'endDate' &&
                name !== 'startDate' &&
                name !== 'orderDirection' &&
                name !== 'vendorIds' &&
                name !== 'locationId' &&
                name !== 'orderBy' && (
                  <FilterMark
                    key={name}
                    filterName={name}
                    filterValue={value}
                    atom={dropoffsFiltersState}
                  />
                ),
            )}
        </div>
        {/* Metrics */}
        <div className="w-full">
          {metrics && (
            <div className="flex flex-row justify-between gap-x-3 ">
              <StatCard
                color="text-greenlight"
                stat={`${formatNumber(
                  metrics?.getDropoffsStats?.data.totals.total_gallons || 0,
                )} Gal`}
                subtitle={t('dropoffs.graph1')}
                type="small"
              />
              <StatCard
                color="text-greenlight"
                stat={formatNumberPercentage(
                  metrics?.getDropoffsStats?.data.totals.miu_percentage,
                )}
                subtitle={t('dropoffs.graph2')}
                type="small"
              />
              <StatCard
                color="text-greenlight"
                stat={formatNumberMoney(
                  metrics?.getDropoffsStats?.data.totalPayments,
                )}
                subtitle={t('dropoffs.graph3')}
                type="small"
              />
            </div>
          )}
        </div>
        {/* List */}
        <div className="mt-5 w-full">
          <div className="mb-10 flex w-full flex-col gap-y-2">
            {dropoffs && !loading && dropoffs.length === 0 && (
              <div className="flex justify-center">
                <p className="text-center text-xs italic">
                  {t('vendor-overall.no-timeline-items')}
                </p>
              </div>
            )}
            {loading && <LoaderSmall />}
            {dropoffs &&
              [...dropoffs].map((dropoff) => (
                <DropoffCard
                  key={dropoff.id}
                  vendorId={dropoff.vendor?.id}
                  vendorName={dropoff.vendor?.name}
                  dropoff={dropoff}
                  loadingSingleQuery={loadingSingleQuery}
                  refetchSingleDropoff={refetchSingleDropoff}
                  dropdownOptions={{
                    driverOptions:
                      options?.getDropoffOptions.data.driverOptions.filter(
                        (o) => o.vendor_id === dropoff.vendor_id,
                      ) || [],
                    locationOptions:
                      options?.getDropoffOptions.data.locationOptions || [],
                    userOptions:
                      options?.getDropoffOptions.data.userOptions || [],
                  }}
                />
              ))}
            {(loading || hasNextPage) && (
              <SentryInfiniteScroll ref={sentryRef}>
                <LoaderSmall />
              </SentryInfiniteScroll>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DropoffsView;
