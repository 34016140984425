import { gql } from '@apollo/client';

export const GET_NOTES_QUERY = gql`
  query GetRouteById($routeId: String!) {
    getRouteById(routeId: $routeId) {
      status
      code
      error
      data {
        notes {
          id
          user_id
          route_id
          note
          createdAt
          updatedAt
          deletedAt
          client_id
          user_name
        }
      }
    }
  }
`;

export const GET_NOTES_BY_ROUTEID = gql`
  query GetNotesByRouteId($routeId: String!, $limit: Int, $offset: Int) {
    getNotesByRouteId(routeId: $routeId, limit: $limit, offset: $offset) {
      status
      code
      count
      error
      data {
        id
        client_id
        user_id
        route_id
        note
        createdAt
        updatedAt
        deletedAt
        user_name
      }
    }
  }
`;

export const GET_NOTES_BY_DROPOFFID = gql`
  query GetNotesByDropoffId($dropoffId: String!, $limit: Int, $offset: Int) {
    getNotesByDropoffId(dropoffId: $dropoffId, limit: $limit, offset: $offset) {
      status
      code
      count
      error
      data {
        id
        vendor_id
        user_id
        dropoff_id
        note
        createdAt
        updatedAt
        deletedAt
        user_name
      }
    }
  }
`;
