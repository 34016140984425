import type { ReactNode } from 'react';
import React, { useState } from 'react';

import arrowdown from '../../../assets/icons/arrow-down.svg';

interface DropdownWithContentProps {
  renderComponent: ReactNode;
  label: string;
}

const DropdownWithContent: React.FC<DropdownWithContentProps> = ({
  renderComponent,
  label,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mx-auto flex w-full flex-col text-left">
      <button
        onClick={toggleDropdown}
        type="button"
        className=" inline-flex h-7 w-full items-center justify-between text-lg font-semibold"
        aria-expanded={isOpen}
      >
        {label}
        <span className={`relative ml-2 h-5 w-5 ${isOpen ? 'rotate-180' : ''}`}>
          <img src={arrowdown} alt="Arrow Down" width="22" height="22" />{' '}
        </span>
      </button>

      <div className={`w-full origin-top-right rounded-md ring-black`}>
        <div className="flex items-center justify-center">
          {isOpen ? renderComponent : <></>}
        </div>
      </div>
    </div>
  );
};

export default DropdownWithContent;
