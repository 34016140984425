import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import balanceIcon from '../../../../../assets/icons/balance.svg';
import editIcon from '../../../../../assets/icons/edit.svg';
import lockIcon from '../../../../../assets/icons/lock.svg';
import pricePerPoundIcon from '../../../../../assets/icons/price_per_pound.svg';
import saveIcon from '../../../../../assets/icons/save-small.svg';
import { PERMISSIONS } from '../../../../../config/permissions';
import { usePermissions } from '../../../../../hooks/usePermission';
import type { GetVendorByIdResponse } from '../../../../../types/vendors';
import { formatNumberMoney } from '../../../../../utils/numberFormatter';

type Props = {
  data?: GetVendorByIdResponse;
  updateVendor: (arg: any) => void;
  vendor: string;
};

const VendorOwnerFixedCards = ({ updateVendor, vendor, data }: Props) => {
  const { checkSinglePermission } = usePermissions();
  const { t } = useTranslation('common');
  // === STATES ===
  // PricePerPound
  const [pricePerPound, setPricePerPound] = useState<number | string>(
    data?.getVendorById?.data.price_per_pound || '',
  );
  const [isEditingPricePerPound, setIsEditingPricePerPound] =
    useState<boolean>(false);
  const [isHoveringPricePerPound, setIsHoveringPricePerPound] =
    useState<boolean>(false);

  const resetIsEditingStates = () => {
    setIsEditingPricePerPound(false);
  };

  const handleSavePricePerPound = () => {
    updateVendor({
      variables: {
        vendorId: vendor,
        key: 'price_per_pound',
        value: String(pricePerPound),
      },
    });
    setIsEditingPricePerPound(false);
    resetIsEditingStates();
  };

  useEffect(() => {
    setPricePerPound(data?.getVendorById?.data.price_per_pound || 0);
  }, [data]);

  return (
    <>
      <div className="flex flex-col gap-2 rounded-lg bg-goldextralight pb-10 pl-6 pr-3 pt-6">
        <div>
          <h1 className="text-3xl font-[700]">
            {t('single-vendor.fixedCardPaymentTtitle')}
          </h1>
        </div>
        {checkSinglePermission(PERMISSIONS.READ_VENDORS_PRICE_POUND) ? (
          <div
            onMouseOver={() => setIsHoveringPricePerPound(true)}
            onMouseLeave={() => setIsHoveringPricePerPound(false)}
            className="mt-3 flex justify-between gap-2 pr-2"
          >
            {isEditingPricePerPound ? (
              <div className="flex items-center gap-2">
                <img
                  className="h-[16px]"
                  src={pricePerPoundIcon}
                  alt="pricePerPound icon"
                />
                <input
                  onChange={(e) =>
                    setPricePerPound(
                      e.target.value ? Number(e.target.value) : '',
                    )
                  }
                  type="number"
                  id="pricePerPound"
                  className="focs:outline-none w-[180px] border-x-0 border-b-2 border-t-0 border-graydark bg-transparent p-0 pr-2 text-xs font-medium outline-none focus:ring-0"
                  value={pricePerPound}
                />
              </div>
            ) : (
              <div className="flex w-[calc(100%-16px)] items-center gap-2">
                <img
                  className="h-[16px]"
                  src={pricePerPoundIcon}
                  alt="pricePerPound icon"
                />
                <p className="text-xs font-medium">{`${formatNumberMoney(
                  pricePerPound,
                  3,
                )} / UCO`}</p>
              </div>
            )}

            {isEditingPricePerPound ? (
              <button
                className={`${
                  isEditingPricePerPound ? 'min-w-[16px]' : 'hidden'
                }`}
                onClick={handleSavePricePerPound}
                id="savePricePerPound"
              >
                <img src={saveIcon} alt="edit icon" />
              </button>
            ) : (
              <button
                className={`${
                  isHoveringPricePerPound ? 'min-w-[16px]' : 'hidden'
                }`}
                onClick={() =>
                  setIsEditingPricePerPound(!isEditingPricePerPound)
                }
                id="editPricePerPound"
              >
                <img src={editIcon} alt="edit icon" />
              </button>
            )}
          </div>
        ) : (
          <div className="flex flex-row items-center gap-x-1">
            <img
              className="h-[16px]"
              src={pricePerPoundIcon}
              alt="pricePerPound icon"
            />
            <span className="flex flex-row gap-x-1 text-xs font-medium">
              <img src={lockIcon} alt="lock icon" />
              {' / UCO'}
            </span>
          </div>
        )}

        <div className="flex justify-between gap-2 pr-2">
          <div className="flex w-[calc(100%-16px)] items-center gap-2 truncate ">
            <img className="h-[16px]" src={balanceIcon} alt="balance icon" />
            <p className="text-xs font-medium">
              {formatNumberMoney(data?.getVendorById?.data.balance || 0)}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorOwnerFixedCards;
